import React from "react";
import theme from "theme";
import { Theme, Em, Text, Section, Button, Image, Box, Link, List, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				ВелоГеній
			</title>
			<meta name={"description"} content={"Ласкаво просимо до ВелоГеній — майстерні для справжніх фанатів велосипедів!\n"} />
			<meta property={"og:title"} content={"ВелоГеній"} />
			<meta property={"og:description"} content={"Ласкаво просимо до ВелоГеній — майстерні для справжніх фанатів велосипедів!\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
		</Components.Header>
		<Components.Header2 />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/mechanic-repairing-bicycle-rear-wheel-royalty-free-image-1639089773.jpg?v=2024-09-20T10:02:55.418Z) center center/cover"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text
						as="h1"
						font="--headline1"
						md-font="--headline2"
						margin="10px 0"
						color="--light"
						border-color="--color-light"
					>
						<Em>
							Ласкаво просимо до ВелоГеній — майстерні для справжніх фанатів велосипедів!
						</Em>
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0" background="--color-light">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				У ВелоГеній ми забезпечуємо кожен велосипед якісним обслуговуванням, щоб ви завжди відчували себе впевнено на дорозі.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Незалежно від того, чи це ваша щоденна поїздка, відпочинок на вихідних, чи підготовка до змагань, наші фахівці забезпечать ваш велосипед першокласним обслуговуванням. Ми віддані якості та швидкості обслуговування, щоб ваш байк завжди був у найкращій формі.
			</Text>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				type="link"
				text-decoration-line="initial"
				href="/services"
			>
				Наші послуги
			</Button>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Cards-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 1.5rem 0px"
				font="normal 600 64px/1.2 --fontFamily-sans"
				text-align="center"
				md-margin="0px 0px 40px 0px"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Про нас
			</Text>
			<Text margin="0px 0px 2rem 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
				ВелоГеній — це місце, де велосипедні ентузіасти знаходять професійну допомогу та підтримку. З нашою командою майстрів ваш велосипед отримає найкращий догляд та обслуговування.
			</Text>
			<Text margin="0px 0px 2rem 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
				Ми пропонуємо повний спектр послуг — від найпростіших налаштувань до капітальних ремонтів. Використовуючи передові інструменти та технології, наші спеціалісти вирішують будь-які проблеми з вашим велосипедом, гарантуючи його безпечну та надійну роботу.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 60px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="46px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="50px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10:02:55.402Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#dcfc96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Професійні майстри
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Наші техніки мають багаторічний досвід та щиро люблять свою справу. Вони постійно вдосконалюють свої знання та навички, щоб надавати найвищий рівень обслуговування.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10:02:55.410Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#fced96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Найкращі матеріали
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Ми використовуємо лише перевірені та якісні запчастини, щоб забезпечити довготривалість вашого велосипеда після ремонту.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="80px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10:02:55.414Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#c5fcff"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Оперативність
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Ми швидко та якісно виконуємо всі роботи, розуміючи, як важливо вам повернутись до поїздок якнайшвидше.
					</Text>
				</Box>
			</Box>
			<Text margin="0px 0px 2rem 0px" font="--headline3" color="--darkL1">
				Наші послуги охоплюють все — від найменших виправлень до складного технічного обслуговування.
			</Text>
			<Text margin="0px 0px 2rem 0px" font="--headline2" color="--darkL1">
				Ми завжди готові вислухати ваші потреби та запропонувати індивідуальні рішення, які відповідатимуть вашим очікуванням.
			</Text>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				type="link"
				text-decoration-line="initial"
				href="/services"
			>
				Дізнатися більше
			</Button>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 2rem 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							У ВелоГеній ми не лише займаємось ремонтом, а й дбаємо про екологію. Ми активно впроваджуємо екологічні практики у нашій роботі, переробляючи відходи та використовуючи екологічно безпечні матеріали.
						</Text>
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							<Em font="--headline3">
								Ми також підтримуємо місцеві громади та проводимо освітні семінари для велосипедистів, допомагаючи їм краще доглядати за своїм транспортом.
							</Em>
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0" quarkly-title="Footer-1">
			<List
				margin="0px 0px 0px 0px"
				padding="12px 0px 12px 0px"
				list-style-type="none"
				as="ul"
				display="flex"
				align-items="center"
				justify-content="center"
			>
				<Link
					href="/index"
					color="white"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="20px/30px sans-serif"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
				>
					Головна сторінка
				</Link>
				<Link
					href="/about"
					color="white"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					font="20px/30px sans-serif"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
					display="none"
				>
					Про нас
				</Link>
				<Link
					href="/services"
					color="white"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="20px/30px sans-serif"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
				>
					Послуги
				</Link>
				<Link
					href="/services"
					color="white"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					display="flex"
					font="20px/30px sans-serif"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
				>
					Політика Конфіденційності
				</Link>
				<Link
					href="/contacts"
					color="white"
					padding="6px 12px 6px 12px"
					text-decoration-line="initial"
					font="20px/30px sans-serif"
					hover-color="--lightD2"
					transition="background-color 0.1s ease 0s"
					display="none"
				>
					Mga contact
				</Link>
			</List>
			<Link
				href="mailto:hello@company.com"
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
				font="--headline3"
			>
				<br />
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--headline2"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						margin="0px auto 0px auto"
						max-width="90%"
					>
						<Span
							font-weight="normal"
							font="--lead"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}Довіртеся нам як спеціалістам з ремонту велосипедів, і дозвольте нам гарантувати,що кожна поїздка буде ідеальною.
						</Span>
						<br />
					</Em>
				</Span>
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Proskurivs'koho Pidpillya St, 86,{" "}
					<br />
					Khmelnytskyi, Khmelnytskyi Oblast, 29013
					<br />
					<br />
					0679877941
				</Em>
			</Link>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});